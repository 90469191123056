export default class {
	constructor() {
		this.init();
		this.gNavToggle();
	}
	init() {

		// アコーディオン
		document.addEventListener("DOMContentLoaded", () => {
			document.querySelectorAll(".js-accordion").forEach(function (el) {
				const summary = el.querySelector(".js-accordion__trigger");
				const answer = el.querySelector(".js-accordion__contents");

				// クリックしたとき
				summary.addEventListener("click", (event) => {
					// デフォルトの挙動を無効化
					event.preventDefault();
					// detailsのopen属性を判定
					if (el.getAttribute("open") !== null) {
						// アコーディオンを閉じるときの処理
						const closingAnim = answer.animate(closingAnimation(answer), animTiming);

						closingAnim.onfinish = () => {
							// アニメーションの完了後にopen属性を取り除く
							el.removeAttribute("open");
						};
					} else {
						// open属性を付与
						el.setAttribute("open", "true");
						// アコーディオンを開くときの処理
						const openingAnim = answer.animate(openingAnimation(answer), animTiming);
					}
				});
			});
		});

		// アニメーションの時間とイージング
		const animTiming = {
			duration: 400,
			easing: "ease-in-out",
		};

		// アコーディオンを閉じるときのキーフレーム
		const closingAnimation = (answer) => [
			{
				height: answer.offsetHeight + "px",
				opacity: 1,
			},
			{
				height: 0,
				opacity: 0,
			},
		];

		// アコーディオンを開くときのキーフレーム
		const openingAnimation = (answer) => [
			{
				height: 0,
				opacity: 0,
			},
			{
				height: answer.offsetHeight + "px",
				opacity: 1,
			},
		];
	}

	gNavToggle() {
		const trigger = document.querySelectorAll('.js-gnav-toggle__trigger');
		const contents = document.querySelectorAll('.js-gnav-toggle__contents');
		this.toggleClass(trigger, contents);
	}

	toggleClass(triggerAry, contentAry) {
		const activeClass = 'is-active';
		if (triggerAry) {
			triggerAry.forEach((element,index) => {
				element.addEventListener('click', () => {
					if (element.classList.contains(activeClass)) {
						triggerAry[index].classList.remove(activeClass);
						contentAry[index].classList.remove(activeClass);

					} else {
						for (let index = 0; index < triggerAry.length; index++) {
							triggerAry[index].classList.remove(activeClass);
						}
						for (let index = 0; index < contentAry.length; index++) {
							contentAry[index].classList.remove(activeClass);
						}
						triggerAry[index].classList.add(activeClass);
						contentAry[index].classList.add(activeClass);
					}
				})
			});
		}
	}
}