import "../scss/style.scss";

/**
 * @babel/polyfill の利用
 * ※ PromiseやArray.includes()など、構文ではない機能的な仕様を利用するために必要
 */
import "@babel/polyfill";

import lazysizes from "lazysizes"
import unveil from "lazysizes/plugins/unveilhooks/ls.unveilhooks.min.js"

// if ('NodeList' in window && !NodeList.prototype.forEach) {
//     NodeList.prototype.forEach = function (callback, thisArg) {
//         thisArg = thisArg || window;
//         for (var i = 0; i < this.length; i++) {
//             callback.call(thisArg, this[i], i, this);
//         }
//     };
// }

import Accordion from "./lib/Accordion";
import Drawer from "./lib/Drawer";
import Headroom from "./lib/Headroom";
import ResponsiveImages from "./lib/ResponsiveImages";
import switchViewport from "./lib/switchViewport";
import Tab from "./lib/Tab";
import Modal from "./lib/Modal";
import Header from "./lib/Header";
import Scroll from "./lib/Scroll";
import MouseStalker from "./lib/MouseStalker";
import Number from "./lib/Number";
// import Form from "./lib/Form";
// import TextAnimation from "./lib/TextAnimation";
//import ScrollHint from "./lib/ScrollHint";
// import Slider from "./lib/Slider";

new Accordion();
new Drawer();
new Headroom();
new ResponsiveImages();
new switchViewport();
new Tab();
new Modal();
new Header();
new Scroll();
new MouseStalker();
new Number();
// new Form();
//new TextAnimation();
//new ScrollHint();
// new Slider();