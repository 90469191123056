export default class {
  constructor() {
    this.init();
  }
	init() {
    window.addEventListener('DOMContentLoaded', function(){

      // マウスストーカーが発火するエリア
      const stalker = document.querySelector('.js-mouseStalker');
      const links = document.querySelectorAll('.js-mouseStalker__link');


      // normalStalkerのイベント内容
      function normalStalker(e) {

        // カーソル位置情報を取得
        const mousePosX = e.clientX;
        const mousePosY = e.clientY;
        const mouse = document.getElementById('js-stalker');

        // 表示域の横幅を取得
        const mouseWidth = mouse.clientWidth;
        const cssPosAjust = mouseWidth / 2;
        const x = mousePosX - cssPosAjust;
        const y = mousePosY - cssPosAjust;

        //カーソルの位置情報を「js-stalker」に反映
        mouse.style.left = x + 'px';
        mouse.style.top = y + 'px';

      }

      links.forEach(function (link) {

        link.addEventListener("mousemove", function (e) {
          normalStalker(e);

          // リンクにカーソルが乗ったときis-active付与
          stalker.classList.add("is-active");

        });

        link.addEventListener("mouseleave", function () {

          // リンクからカーソルが離れたときにis-active削除
          stalker.classList.remove("is-active");

        });
      });

    });

	}
}