
export default class {
  constructor() {

    this.opened = false
    this.body = document.body;

    this.header = ".js-header";
    this.drawer = ".js-drawer";
    this.hamburger = ".js-drawer-hamburger";
    this.openClass = "is-open";
    this.init();

    this.drawerItemLink = ".js-drawer a";
    this.currentPage()

  }

  currentPage(){
    const drawerItemLinks = document.querySelectorAll(this.drawerItemLink);
    drawerItemLinks.forEach( (el) => {
      el.addEventListener('click',(e)=>{
        this.close();
      })
    })
  }

  init() {
    this.header = document.querySelector(this.header);
    this.drawer = document.querySelector(this.drawer);
    this.hamburger = document.querySelector(this.hamburger);
    if(this.drawer) {
      if(this.hamburger) {
        this.hamburger.addEventListener('click', (e) => {
          this.drawerClick(e);
        })
      }
    }
  }
  drawerClick(e) {
    this.drawerToggleClass();
  }
  drawerToggleClass() {
    if(!this.opened){
      this.open();
    }else{
      this.close();
    }
  }
  open() {
    // this.disableBodyScroll(this.body, this.options);
    this.drawer.style.display = 'block';
    setTimeout(() =>{
      this.drawer.setAttribute('aria-hidden', false);
      this.drawer.classList.add(this.openClass);
      this.header.classList.add(this.openClass);
      this.hamburger.setAttribute('aria-expanded', true);
      this.hamburger.setAttribute('aria-label', 'メニューを閉じる');

      this.opened = true;

      document.querySelector('#btn').textContent = '閉じる';

    }, 100);
  }
  close() {
    this.drawer.setAttribute('aria-hidden', true);
    this.header.classList.remove(this.openClass);
    this.drawer.classList.remove(this.openClass);
    this.hamburger.setAttribute('aria-expanded', false);
    this.hamburger.setAttribute('aria-label', 'メニューを開く');

    document.querySelector('#btn').textContent = 'メニュー';

    this.opened = false;
    setTimeout(() =>{
      this.drawer.style.display = 'none';
    }, 100)
  }
}