import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class {
  constructor() {
    this.init();
    this.fadeElm();
  }
  fadeElm() {
		const wrappers = document.querySelectorAll('.js-fade-wrapper');

    if(wrappers){

      wrappers.forEach(wrapper => {
        const elm = wrapper.querySelectorAll('.js-fade-elm');
        elm.forEach(fadeWrap => {
          fadeWrap.innerHTML = '<span class="js-fade-elm__in">' + fadeWrap.innerHTML + '</span>';
          let item = fadeWrap.querySelectorAll('.js-fade-elm__in');
          setTimeout(() => {
            //親のタイミングで発火
          item.forEach(fadeItem => {
            let itemH = fadeItem.clientHeight;
              fadeItem.style.height = itemH + 'px';
            });
          }, 500);
        });
        this.addClass(wrapper);
      });

    }

	}

  addClass(el) {
    ScrollTrigger.create({
      // markers: true,
      trigger: el, //アニメーションが始まるトリガーとなる要素
      start: 'top 75%',
      end: 'bottom 75%',
      toggleClass: {
        targets: el,
        className: "is-active"
      },
      once: true
    });
  }

  init() {
    const fadeItems = gsap.utils.toArray('.js-fadein');
    fadeItems.forEach((fadeItem) => {
      this.addClass(fadeItem);
    });

    gsap.to( ".js-hero", {
      scrollTrigger: ".js-hero",
      x: 0
    });
  }
}