export default class {
  constructor() {
    this.numberInput = document.querySelectorAll('.js-number-input');
    this.init();
  }
  init() {
    const number = this.numberInput;

    number.forEach((number) => {
      if (number) {
        number.setAttribute('inputmode','tel');
      }
    });
  }
}